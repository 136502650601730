var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: _vm.pageOpts,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.author",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.replace(/\s\S+@\S+.\S+/g, ''))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.currentLib = item}}},on),[_c('v-icon',[_vm._v("mdi mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("More Info")])]),_c('project-add-lib',{attrs:{"lib":item}})]}}])}),_c('v-dialog',{attrs:{"value":!!_vm.currentLib,"max-width":"500"},on:{"input":function($event){_vm.currentLib = null}}},[(_vm.currentLib)?_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.currentLib.name)+" "),_c('v-spacer'),_c('v-chip',{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.currentLib.version)+" ")])],1),_c('v-card-text',[_c('pre',[_vm._v(_vm._s(_vm.getDesc(_vm.currentLib)))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"href":_vm.currentLib.website,"target":"_blank","color":"info","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" Website ")],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.currentLib = null}}},[_vm._v(" Close ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }